import * as React from "react";
import { graphql } from "gatsby";
import 'twin.macro';

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Section, SectionHeader } from "../components/section";
import PostCard from "../components/postCard";
import Pagination from "../components/pagination";

const BlogArchiveTemplate = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={`category:${pageContext.cat} posts`} />
      <Section>
        <SectionHeader>category: {pageContext.cat}</SectionHeader>
        <ol tw="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          {posts.map(post => {
            return (
              <li key={post.fields.slug}>
                <PostCard post={post} />
              </li>
            )
          })}
        </ol>
        <Pagination path={`/articles`} numPages={pageContext.numPages} currentPage={pageContext.currentPage} />
      </Section>
    </Layout>
  )
}

export default BlogArchiveTemplate;

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $cat: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx (
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {fields: {}, frontmatter: {categories: {eq: $cat}}}
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date
          title
          description
          eyecatch {
            childImageSharp {
              gatsbyImageData (
                placeholder: DOMINANT_COLOR
                aspectRatio: 1.618
              )
            }
          }
          categories
          tags
        }
      }
    }
  }
`;
