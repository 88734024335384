import React from "react";
import 'twin.macro';
import { Link } from "gatsby";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-light-svg-icons';

library.add(faAngleRight, faAngleLeft);

const Pagination = (props) => {
  const { path, numPages, currentPage } = props;

  return (
    <ul tw="mt-16 flex justify-between" {...props}>
      <li tw="flex-1 text-left">
        { (1 === currentPage-1) && <Link to={path}>
          <FontAwesomeIcon tw="mr-4" icon={["fal", "angle-left"]} />
          Prev
        </Link> }
        { (1 < currentPage-1) && <Link to={`${path}/${currentPage-1}`}>
          <FontAwesomeIcon tw="mr-4" icon={["fal", "angle-left"]} />
          Prev
        </Link> }
      </li>
      <li tw="flex-auto text-center">
        {currentPage} / {numPages}
      </li>
      <li tw="flex-1 text-right">
        { (currentPage+1 <= numPages) && <Link to={`${path}/${currentPage+1}`}>
          Next
          <FontAwesomeIcon tw="ml-4" icon={["fal", "angle-right"]} />
        </Link> }
      </li>
    </ul>
  );
}

export default Pagination;
